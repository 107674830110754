import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Messages from '../shared/snackbar/Messages';
import LogoFB from '../../assets/logos/fb-logo.svg';
import LogoTestimonial from '../../assets/images/testimonail_logo.svg';
import AuthMessage from '../pages-content/auth/AuthMessage';

const LayoutWithoutNavbarForSignUps = ({ children }) => {
  useEffect(() => {
    document.body.style.backgroundColor = '#FFFCF0';

    return () => {
      document.body.style.backgroundColor = '#fff';
    };
  }, []);

  return (
    <div className='sign-up-container fb-container'>
      <Messages />
      <AuthMessage />
      <div className='content-wrapper'>
        <Link to='/'>
          <img className='site-logo' src={LogoFB} alt='Grit search' />
        </Link>
        <div className='form-content-wrapper'>{children}</div>
      </div>
      <div className='details-wrapper'>
        <div className='title-component'>
          <img src={LogoTestimonial} alt='testimonial' />
          <div className='title-names'>
            HR Director
            <br />
            <span>aAgritech</span>
          </div>
        </div>
        <div className='upper-title-holder'>
          <p className='upper-title'>
            “The Freshlybaked platform consolidates all candidate profiles in
            one convenient location. It&apos;s highly intuitive, offering
            comprehensive information at a glance. This streamlines the process,
            reducing the need for repeated communication with recruiters to
            obtain candidate details, thus saving time and effort. With this
            tool, one can efficiently shortlist candidates without relying on
            others, making it an invaluable asset.”
          </p>
          <div className='image-container' />
        </div>
      </div>
    </div>
  );
};

export default LayoutWithoutNavbarForSignUps;
